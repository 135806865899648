import React from "react"
import { Link } from "gatsby"
import { connect } from "react-redux"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Cardy from "../components/support-cards"
import { Header, Grid } from "semantic-ui-react"
import "../components/layout.css"

class Support extends React.Component {
  handleClick() {
    console.log("link to hp was clicked")
  }
  render() {
    const { lang } = this.props
    return (
      <Layout>
        <SEO title={lang === "english" ? "Tech Support" : "Soporte Técnico"} />
        <Header size="huge" style={{ textAlign: "center", marginTop: "3rem" }}>
          {lang === "english" ? "Technical Support" : "Soporte Técnico"}
        </Header>
        <div
          style={{
            marginTop: "3rem",
            marginBottom: "3rem",
            textAlign: "center",
          }}
        >
          {/* <p
            style={{
              margin: "auto",
              fontSize: "1rem",
              fontWeight: "bold",
              textAlign: "center",
              maxWidth: "800px", */}
          {/* // textIndent: "20px",
              // paddingLeft: "20px",
              // paddingRight: "20px",
            }} */}
          {/* > */}
          {/* <a
              href="http://www.techcommwireless.com/"
              target="_blank"
              rel="noreferrer"
            >
              Techcomm Wireless
            </a> */}
          {/* {lang === "english"
              ? "Techcomm Wireless provides local tech support"
              : "Techcomm Wireless proporciona soporte técnico local"}
          </p> */}
          <p
            style={{
              margin: "auto",
              marginTop: "1rem",
              fontSize: "1rem",
              fontWeight: "bold",
              textAlign: "center",
              maxWidth: "800px",
              // textIndent: "20px",
              // paddingLeft: "20px",
              // paddingRight: "20px",
            }}
          >
            {lang === "english"
              ? "Find the nearest office below or email info@techcommwireless.com"
              : "Encuentre la oficina más cercana a continuación o por favor envíe un correo electrónico a info@techcommwireless.com"}
          </p>
        </div>
        {/* <Grid>
          <Grid.Row>
            <Grid.Column> */}
        <Cardy />
        {/* </Grid.Column>
          </Grid.Row>
        </Grid> */}
        <div
          style={{
            marginTop: "3rem",
            marginBottom: "3rem",
            textAlign: "center",
          }}
        >
          <p
            style={{
              margin: "auto",
              fontSize: "1rem",
              fontWeight: "bold",
              textAlign: "center",
              maxWidth: "800px",
              // textIndent: "20px",
              // paddingLeft: "20px",
              // paddingRight: "20px",
            }}
          >
            {lang === "english"
              ? "For sales support go to our "
              : "Para llegar al soporte de ventas visita "}
            <Link to="/contact/">
              {lang === "english" ? "contact page" : "pagina de contacto"}
            </Link>
          </p>
        </div>
      </Layout>
    )
  }
}

export default connect(
  state => ({
    lang: state.lang,
  }),
  null
)(Support)
