import React from "react"
import { connect } from "react-redux"
import { Card, Accordion, Icon, Flag } from "semantic-ui-react"
import "./layout.css"

const offices = {
  us: {
    eng: "United States of America",
    esp: "Estados Unidos de América",
    phone: "+ 1 305 513 0214",
    addresses: [
      {
        street: "3529 NW 115th Ave.",
        city: "Miami",
        state: "Florida",
        zip: "33178",
        phone: "",
      },
    ],
  },

  mx: {
    eng: "Mexico",
    esp: "Estados Unidos Mexicanos",
    phone: "+ 1 305 513 0214",
    addresses: [
      {
        street: "Av. Ignacio Luis Vallarta №. 3155",
        city: "Guadalajara",
        state: "Jalisco",
        zip: "44110",
        phone: "+55 4744 4490 ext 3402",
      },
      {
        street: "Ctra. Barranca Honda, Nave 05, Col. San Martín Obispo Park II",
        city: "Cuautitlán Izcalli",
        state: "Estado de México",
        zip: "54763",
        phone: "+55 4744 4900 ext 4901",
      },
      {
        street: "Paseo de los Héroes № 9211, Zona Urbana Rio",
        city: "Tijuana",
        state: "Baja California",
        zip: "22010",
        phone: "+55 4744 4900",
      },
    ],
  },

  gt: {
    eng: "Guatemala",
    esp: "República de Guatemala",
    phone: "+ 502 2212 7705",
    addresses: [
      {
        street:
          "17 Calle 14-99, Complejo Industrial El Naranjo, Distribodegas 4 Bodega 5",
        city: "Ciudad de Guatemala",
        state: "",
        zip: "",
        phone: "",
      },
    ],
  },

  pa: {
    eng: "Panama",
    esp: "República de Panamá",
    phone: "+507 310 0929",
    addresses: [
      {
        street: "Vía Brasil #12709, corregimiento de Bella Vista",
        city: "Cuidad de Panamá",
        state: "",
        zip: "",
        phone: "",
      },
    ],
  },

  cr: {
    eng: "Costa Rica",
    esp: "República de Costa Rica",
    phone: "+506 4080 8300",
    addresses: [
      {
        street:
          "Ofibodegas Coyol N°1, Calle los llanos, Contiguo a Mayca, el Coyol de Alajuela",
        city: "Alajuela",
        state: "Provincia de Alajuela",
        zip: "",
        phone: "",
      },
    ],
  },

  do: {
    eng: "Dominican Republic",
    esp: "República Dominicana",
    phone: "+809 332 1042",
    addresses: [
      {
        street: "C/ La Sonrisa #8A, Pantoja, Los Alcarrizos",
        city: "Santo Domingo Oeste",
        state: "",
        zip: "",
        phone: "+809 332 1042",
      },
      {
        street: "Rómulo Betancourt №.1238, Bella Vista",
        city: "Santo Domingo",
        state: "",
        zip: "",
        phone: "+809 582 8124",
      },
      {
        street: "C/14 #19A, Jardines Metropolitano",
        city: "Santo Domingo",
        state: "",
        zip: "",
        phone: "+809 724 4112",
      },
    ],
  },
}

class CardEx extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.intiate(offices)
  }

  intiate = obj => {
    const nObj = {}
    Object.entries(obj).map(o => (nObj[o[0]] = false))
    return nObj
  }

  handleClick = (e, id) => {
    e.preventDefault()
    console.log(id)
    this.setState(prevState => ({
      ...prevState,
      [id]: !this.state[id],
    }))
  }

  render() {
    const { lang } = this.props
    console.log(this.state)

    return (
      <Card.Group style={{ justifyContent: "center", alignItems: "center" }}>
        {Object.entries(offices).map(o => (
          <Card key={o[0]} onClick={e => this.handleClick(e, o[0])}>
            <Card.Content>
              <Card.Header>
                <Flag name={o[0]} />
                {lang === "english" ? ` ${o[1].eng}` : ` ${o[1].esp}`}
              </Card.Header>
              <p style={{ fontSize: "1.2rem" }}>
                <Icon name="phone" size="small" />
                {o[1].phone}
              </p>
              <Card.Description as={Accordion}>
                <Accordion.Title active={this.state[o[0]]}>
                  <Icon name="dropdown" />
                  {lang === "english" ? "Address(es)" : "Dirección(es)"}
                </Accordion.Title>
                <Accordion.Content active={this.state[o[0]]}>
                  {o[1].addresses.map(a => (
                    <div style={{ marginTop: "1em" }}>
                      <h4 style={{ color: "black", marginBottom: ".2em" }}>
                        {a.city}
                      </h4>
                      <p style={{ fontWeight: "bold", color: "#74005F" }}>
                        <Icon name="phone" size="small" />
                        {a.phone}
                      </p>
                      <p>{a.street}</p>
                      <p>{a.city + a.zip}</p>
                    </div>
                  ))}
                </Accordion.Content>
              </Card.Description>
            </Card.Content>
          </Card>
        ))}
      </Card.Group>
    )
  }
}

export default connect(
  state => ({
    lang: state.lang,
  }),
  null
)(CardEx)
